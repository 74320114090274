import React from "react"
import { graphql, PageProps } from "gatsby"
import { Query } from "@graphql-types"
import { SEO, Header, FormBlock } from "@global"
import { colors } from "@util/constants"

interface Props extends PageProps {
  data: Query
}

const BlogTemplate = ({ data }: Props) => {
  if (data.sanityGuide == null) return null

  const { seo, content, title, categories } = data.sanityGuide

  const headerColor = (categories && categories[0]?.colorList) ?? colors.maroon

  const headerData = {
    headerColor,
  }

  return (
    <div className="subpage-container">
      <SEO data={seo} />
      <Header title={title} headerData={headerData} />
      <FormBlock data={content} />
    </div>
  )
}

export const query = graphql`
  query singleGuideQuery($slug: String) {
    sanityGuide(slug: { current: { eq: $slug } }) {
      seo {
        ...sanitySeo
      }
      title
      categories {
        colorList
      }
      slug {
        current
      }
      content {
        ...sanityFormBlock
      }
    }
  }
`

export default BlogTemplate
